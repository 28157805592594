import React, { Component } from "react"
import { Link } from 'gatsby';
// import AnchorLink from 'react-anchor-link-smooth-scroll';

import Layout from "../components/layout"
import SEO from "../components/seo"

import SimpleModal from '../components/utilities/SimpleModal';

import "./casestudygenzeon.scss"

import imgQuote from "../images/quotation_mark.webp"

export default ({ pageContext }) => (
    <Layout>
        <SEO
            title={pageContext.yoast.title}
            description={pageContext.yoast.metadesc}
            ogimg={pageContext.yoast.opengraph_image}
            link={pageContext.link}
        />
        <div className="case-study-genzeon-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div
                        className="header-container"
                        style={{
                            backgroundImage: `url(${pageContext.acf.header_image.csOneHedImaLocalFile.childImageSharp.fluid.src})`,
                        }}
                    >
                        <img src={imgQuote} alt="" />
                        <h2>{pageContext.acf.header_text}</h2>
                    </div>
                </div>
            </div>
            <div className="container detail-wrapper">
                <div className="row">                   
                    <div className="col-md-8">
                       <img
                            src={
                                pageContext.acf.client_logo.csOneCliLogLocalFile.childImageSharp
                                    .fluid.src
                            }
                            className="client-logo"
                            width={pageContext.acf.client_logo.media_details.width / 2}
                            height={pageContext.acf.client_logo.media_details.height / 2}
                            alt={pageContext.yoast.opengraph_title}
                        />
                    </div>
                    <div className="col-md-4">
                        <a href="https://www.meritmile.com/uploads/2024/02/merit-mile-genzeon-case-study.pdf" className="btn downloadBtn" target="_blank" rel="nofollow noopener noreferrer" style={{ marginTop: '4em'}}>Download the PDF here</a>
                    </div>
                    <div className="col-12">
                        <div className="bar"></div>
                    </div>
                    <div className="col-12">
                        <div
                            className="first-paragraph"
                            dangerouslySetInnerHTML={{
                                __html: pageContext.acf.first_paragraph,
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="margin-b">
                <div class="container detail-wrapper">
                    <div className="row">
                        <div className="col-md-6">
                            <h3>Challenges & Opportinities</h3>
                            <p>Despite having been in business since 2010 with an existing expertise in custom application development, data, and automation solutions within healthcare and retail, the introduction of AI and Azure services and solutions was unfamiliar territory for the Genzeon sales team. For many, they were venturing into AI and Azure solutions for the first time.</p>
                            <p>With Merit Mile's extensive experience as a Microsoft partner-focused channel marketing and sales enablement team dating back to 2007, Genzeon sought our expertise to navigate these challenges and leverage our proven track record in launching new partner programs, creating impactful sales enablement programs, and running partner demand generation campaigns.</p>
                            <p>Merit Mile was enlisted to address three critical needs. First, we needed to redefine Genzeon's value proposition as a Microsoft partner and clearly articulate their unique differentiators in the context of AI and Azure services. Secondly, the sales team required comprehensive education and enablement on what it meant to be a Microsoft partner, understanding new opportunities and use cases, and how to identify buying groups within their existing accounts. The final challenge involved equipping the sales team with the skills to effectively lead customer meetings armed with this newfound knowledge.</p>
                        </div>
                        <div className="col-md-6">
                            <div className="">
                              <img src={pageContext.acf.desktop_image.csOneDesImaLocalFile.childImageSharp.fluid.src} className="desktop-image img-fluid" alt="Challenges & Opportunities" width={400}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container detail-wrapper">
                <div className="row">
                    <div className="col-12 my-0 py-0">
                        <div
                            className="third-paragraph"
                            dangerouslySetInnerHTML={{
                                __html: pageContext.acf.third_paragraph,
                            }}
                        />
                    </div>
                </div>
                <div class="row">
                  <div class="col-12 mb-5">
                    <div className="mb-5">
                      <img src="https://www.meritmile.com/uploads/2024/02/solutions.webp" className="img-fluid" alt="Genzeon Solutions" />
                    </div>
                    <p>Leveraging over 15 years of extensive experience and familiarity with Microsoft partners and solutions, we bridged the gap between Genzeon's existing strengths and offerings and the integration of new Azure solutions. The outcome of this collaborative effort was the development of a robust messaging and positioning framework. This framework served as the cornerstone for creating both internal sales enablement content and external customer-facing materials. By aligning Genzeon's current strengths with the potential of Azure solutions, Merit Mile successfully established a cohesive foundation that would
guide the sales team in effectively communicating their value proposition as a Microsoft AI Cloud Partner.</p>
                  </div>
                </div>
                <div className="row margin-b">
                    <div className="col-md-6">
                        <img
                            src="https://www.meritmile.com/uploads/2024/02/results.webp"
                            className="solution-image img-fluid"
                            alt="Genzeon Results"
                            width="675px"
                        />
                    </div>
                    <div className="col-md-6">
                      <h3>Results</h3>
                      <p>Merit Mile's collaboration with Genzeon produced impactful results through the development of key sales enablement deliverables.</p>
                      <p>First, we created a comprehensive selling and positioning guide designed to equip Genzeon sellers with the knowledge and tools essential for their transition into Azure solutions. This guide included an understanding of the market landscape, the reasons behind Genzeon's decision to become a Microsoft partner, and the new value proposition and messaging. We introduced distinctive and memorable descriptors, such as "automation aficionados," "value miners," and "rapid innovators," highlighting Genzeon's unique differentiators. The guide also detailed their top use cases in healthcare and retail, along with guidance on target customers, personas, opportunity identification, creating customer empathy, and a competitive SWAT assessment. The sales team was also equipped with a handy sales reference card for quick access and a pre-meeting refresher.</p>
                    </div>
                </div>
                <div className="row margin-b">
                    <div className="col-12">
                      <p>Next, we designed a customer presentation and solution brief, crafted from the messaging framework, carrying the narrative forward in terms resonant with customers. To reinforce these deliverables, Merit Mile conducted comprehensive training sessions for Genzeon's sales, marketing, and product teams. These sessions covered the entire content of the selling and positioning guide and taught the teams how to lead a customer meeting using the new presentation. Multiple training sessions were conducted to reach the entire Genzeon team, resulting in a well-prepared and knowledgeable workforce. As a result, the Genzeon team emerged with a profound understanding of their role as a Microsoft partner, a clear grasp of the solutions they would be selling, and a readiness to engage with customers confidently.</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h3>Deliverables</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <ul>
                            <li>Customer presentation</li>
                            <li>Messaging and positioning framework</li>
                            <li>Sales enablement guide</li>
                        </ul>
                    </div>
                    <div className="col-6">
                        <ul>
                            <li>Sales enablement training sessions</li>
                            <li>Sales reference card</li>
                            <li>Solution brief</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="fullWidthCTA">
                <div className="container">
                    <div className="row">
                        <div className="col-12 pb-5 text-center">
                          <a href="https://www.meritmile.com/uploads/2024/02/merit-mile-genzeon-case-study.pdf" className="btn downloadBtn text-uppercase" target="_blank" rel="nofollow noopener noreferrer" style={{ marginTop: '4em'}}>Download the Case Study</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fullWidthCTA">
                <div className="container">
                    <div className="row">
                        <div className="col-12 pt-4 pb-5">
                            <h5><b>To view more Marketing case studies, please visit <a href="/case-studies/"><b style={{ color: "black" }}>MeritMile.com/case-studies</b></a>. And for more information or to arrange a conversation with a Merit Mile marketing specialist, please email us at <a href="mailto:Hello@MeritMile.com?subject=Genzeon%20Case%20Study%20Inquiry"><b style={{ color: "black" }}>hello@meritmile.com</b></a> or call us at 1-561-362-8888 today!</b></h5>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fullWidthImageWrapper">
                <img
                    src="https://www.meritmile.com/uploads/2021/09/mm-divider.jpg"
                    alt="Merit Mile Icon Divider"
                    className="img-fluid"
                />
            </div>
            <div className="about-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <h2>About Merit Mile</h2>
                        </div>
                    </div>
                
                    <div className="row margin-b">
                        <div className="col-md-6">
                            <p>Established in 2007 in Boca Raton, FL, Merit Mile is a marketing services provider focused on helping companies and corporate marketing departments build brands, drive sales revenue, and garner publicity in their respective industries. Our unique differentiator at Merit Mile is our ability to 100% own the message, the publicity, and the demand generation aspects of the corporate marketing function. Clients hire us and rehire us for our ability to refine their messaging/value proposition, develop top and mid-funnel leads, and get the type of editorial publicity that differentiates them from their competitors. From branding to PR to demand generation, Merit Mile is the high-octane fuel that invigorates corporate growth engines. To learn more, please email us at Hello@MeritMile.com, call us at 1-561-362-888, or visit us at www.MeritMile.com. </p>
                        </div>
                        <div className="col-md-6"><img className="img-fluid" src="https://www.meritmile.com/uploads/2021/06/mm-headquater.jpg" alt="Merit Mile Headquarter in Boca Raton, FL" /></div>
                    </div>
                </div>
            </div>
            <div className="container quote-wrapper">
                <div className="row">
                    <div className="col-12">
                        <p>
                            For more insights and to connect<br />
                            with Merit Mile <a href="mailto:Hello@MeritMile.com?subject=Iridium%20Case%20Study%20Inquiry"><b style={{ color: "black" }}>email us here</b>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
)
